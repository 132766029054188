.ag-root-wrapper {
  --ag-header-background-color: rgb(var(--raw-weak-50));
  --ag-data-color: rgb(var(--raw-strong-950));
  --ag-row-hover-color: rgb(var(--raw-weak-50));
  --ag-background-color: rgb(var(--raw-background));
  --ag-odd-row-background-color: rgb(var(--raw-background));
  --ag-row-border-color: rgb(var(--raw-soft-200));
  --ag-border-color: rgb(var(--raw-soft-200));
  --ag-secondary-foreground-color: rgb(var(--raw-strong-950));
  --ag-icon-font-color: rgb(var(--raw-strong-950));
  --ag-modal-overlay-background-color: rgb(var(--raw-foreground) / 0.1);
  /*--ag-modal-overlay-background-color: rgb(var(--raw-faded-lighter));*/
}

.ag-grid-hammr .ag-root-wrapper {
  border: none;
  @apply rounded-t-8;
  font-family: 'Inter';
}

.ag-grid-hammr .ag-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ag-grid-hammr .ag-header-viewport {
  @apply bg-weak-50;
}

.ag-grid-hammr .ag-header {
  background-color: transparent;
  border-bottom: none;
  @apply mb-2 rounded-b-8;
}

.ag-header {
  & > .ag-pinned-left-header {
    @apply !border-r-0 bg-weak-50;

    .ag-header-cell-resize::after {
      @apply bg-weak-50;
    }
  }

  & > .ag-header-viewport > .ag-header-container {
    .ag-header-cell-resize::after {
      /* here soft-200 doesn't exactly follow figma, but looks better */
      @apply bg-soft-200;
    }
    .ag-sort-indicator-icon {
      @apply pl-1;
    }
  }

  & > .ag-pinned-right-header {
    @apply !border-l-0 bg-weak-50;

    .ag-header-cell-resize::after {
      @apply bg-weak-50;
    }
  }
}

.ag-header-row {
  @apply !text-sub-600;
}
.ag-header-row:has(+ .ag-header-row) {
  @apply rounded-b-none;
}
.ag-header-row + .ag-header-row {
  @apply rounded-t-none border-t border-soft-200;
}

.ag-header-row .ag-header-cell {
  border-top: none !important;
}

.ag-grid-hammr .ag-header-row {
  background-color: var(--ag-header-background-color);
}

.ag-header-cell-text {
  @apply text-sm font-normal text-sub-600;
}

.ag-body {
  @apply text-sm font-normal;
}
.ag-row-group {
  @apply bg-white-0;
}

.ag-row-group:hover {
  @apply bg-background;
}

.ag-row-group:hover {
  background-color: var(--ag-row-hover-color);
}
.ag-row-group .ag-icon:hover {
  @apply !bg-soft-200;
  box-shadow: 0 0 0 4px rgba(var(--raw-soft-200)) !important;
}

.ag-grid-hammr .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  @apply border-r-soft-200/20;
}

.ag-grid-hammr .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  @apply border-l-soft-200/20;
}

.ag-grid-hammr .ag-pinned-right-header {
  @apply border-l-soft-200/20;
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper {
  @apply relative h-4 w-4 rounded border-2 border-soft-200 bg-background;
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input {
  @apply absolute left-1/2 top-1/2 size-5 -translate-x-1/2 -translate-y-1/2;
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper::after {
  content: ' ';
  top: -2px;
  left: -2px;
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper.ag-checked {
  @apply bg-static-white;
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper.ag-checked::after {
  color: rgb(var(--raw-primary-base));
  content: var(--ag-icon-font-code-checkbox-checked, '\f106');
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper.ag-indeterminate {
  @apply bg-static-white;
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper.ag-indeterminate::after {
  color: rgb(var(--raw-primary-base));
  content: var(--ag-icon-font-code-checkbox-indeterminate, '\f107');
}

.ag-grid-hammr .ag-root-wrapper .ag-checkbox-input-wrapper.ag-checked:hover::after {
  color: rgb(var(--raw-primary-darker));
}

.ag-grid-hammr .ag-checkbox-input-wrapper:focus-within,
.ag-grid-hammr .ag-checkbox-input-wrapper:active {
  box-shadow: none;
}

.ag-row-hover::before {
  @apply rounded-3xl;
}

.ag-column-first {
  @apply font-medium;
}
.ag-row-hover .ag-column-first {
  @apply rounded-l-xl;
}

.ag-row-hover .ag-column-last {
  @apply rounded-r-xl;
}

.ag-row-hover .ag-cell-not-inline-editing {
  @apply !bg-weak-50;
}

/* hide the chevron in grouped row */
.ag-group-expanded,
.ag-group-contracted {
  @apply !hidden;
}
.ag-cell-last-left-pinned {
  @apply !border-transparent;
}
